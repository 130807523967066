import flexibility from '@assets/svg/illustration-flexibility.svg'
import live from '@assets/svg/illustration-live.svg'
import time from '@assets/svg/illustration-time.svg'

const mission = [
    {
        id: 1,
        title: 'Gain de temps',
        content: [
            "En utilisant l'application Staffea, vous gagnez un temps considérable dans la gestion de votre planning. Tous les matins, jetez un œil à votre planning pour vérifier vos horaires. Demandez rapidement des ajustements d'horaires en cas de nécessité.",
            "Pour vos demandes de congés, transmettez votre demande directement sur Staffea pour avoir un retour de votre manager. Si vous êtes en arrêt maladie, il vous suffit de télécharger votre arrêt maladie sur l'application : de quoi faciliter les démarches administratives !",
        ],
        svg: time,
    },
    {
        id: 2,
        title: 'Flexibilité',
        content: [
            "Vous avez besoin de vous libérer sur un de vos créneaux ? Pas de panique : grâce à Staffea, demander un échange avec un collègue n'a jamais été aussi facile. Il vous suffit de vous connecter à l'application et de lancer une demande.",
            "La bourse d'échange se charge d'envoyer une notification à vos collègues disponibles sur ce créneau. Si l'un d'entre eux accepte, le planning est automatiquement modifié",
        ],
        svg: flexibility,
    },
    {
        id: 3,
        title: 'Planning en temps réel',
        content: [
            "Avec Staffea, vous n'avez plus aucun risque de vous tromper dans votre planning ! Il est mis à jour en temps réel, en prenant en compte les ajustements d'horaires, les remplacements, les congés et les absences.",
            'A chaque modification de planning, vous recevez une notification immédiate sur votre smartphone. Vous êtes toujours au courant des évolutions dans votre planning',
        ],
        svg: live,
    },
]

export default mission
