import pictoExchange from '@assets/svg/picto-app-exchange-hours.svg'
import pictoPlanning from '@assets/svg/picto-app-planning.svg'
import pictoRealTime from '@assets/svg/picto-app-real-time.svg'
import pictoRequest from '@assets/svg/picto-app-request.svg'

const functionalities = [
    {
        id: 1,
        subTitle: 'Soyez toujours à jour',
        title: 'Accès et consultation de votre propre planning',
        image: pictoPlanning,
        list: [
            'Utilisez une application mobile ergonomique, efficace et sécurisée',
            'Consultez votre planning en temps réel, mois par mois',
            'Transmettez vos feedbacks régulièrement ou après une mission',
        ],
    },

    {
        id: 2,
        subTitle: 'Indiquez quand vous préférez travailler',
        title: 'Partage de vos disponibilités en temps réel',
        image: pictoRealTime,
        list: [
            'Partagez vos éventuelles disponibilités sur vos jours de repos',
            'Acceptez les créneaux à pourvoir en un clic',
            ' Notez vos indisponibilités pour ne pas être sollicité par votre hiérarchie',
        ],
    },

    {
        id: 3,
        subTitle: 'Demandez des changements',
        title: 'Déclaration de vos demandes en ligne',
        image: pictoRequest,
        list: [
            "Utilisez l'application pour demander des ajustements d'horaires",
            'Transmettez vos demandes de congés',
            'Envoyez vos arrêts maladies dématérialisés',
        ],
    },

    {
        id: 4,
        subTitle: 'Gagnez en flexibilité',
        title: "Échanges d'horaires avec votre équipe",
        image: pictoExchange,
        list: [
            'Sollicitez un remplacement sur Staffea',
            "Utilisez la bourse d'échanges de l'application pour permuter vos horaires",
            'Simplifiez la gestion de vos remplacements',
        ],
    },
]

export default functionalities
